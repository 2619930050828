<template>
  <div class="template-container mobile-wrap">
    <div class="wrap-box">
      <!-- banner -->
      <div class="banner-box">
        <img :src="bannerImage" alt="" />

        <!-- 免费试用按钮 -->
        <div
          v-if="freeTrialButtonConfig"
          class="free-btn"
          :style="{
            left: btnPostionStyle.left,
            bottom: btnPostionStyle.bottom,
            color: btnPostionStyle.color,
          }"
          @click="jumpProject"
        >
          <div
            class="btn"
            :style="{
              backgroundColor: btnPostionStyle.backgroundColor,
              color: btnPostionStyle.color,
            }"
          >
            <span>免费试用按钮</span>
          </div>
        </div>
      </div>
      <!-- 项目介绍 -->
      <div class="introduce is-wrapper-box">
        <img :src="introduceText" alt="" />
        <p class="text fontRegular">
          {{ projectIntroduction }}
        </p>
      </div>

      <!-- 页面展示 -->
      <div class="page-show is-wrapper-box">
        <div class="page">
          <img :src="detailContentImage" alt="" />
        </div>

        <!-- 三个圆点 -->
        <!-- <div class="round" v-if="imgShow">
          <div v-for="item in 3"></div>
        </div> -->

        <p class="fontRegular">{{ detailIntroduction }}</p>
      </div>

      <!-- 预览图片 -->
      <div class="end-image is-wrapper-box">
        <img :src="previewImage" alt="" />
        <div class="back-btn fontRegular" @click="jumpBack">返回</div>
      </div>

      <!-- 相关案列 -->
      <div class="case-list is-wrapper-box">
        <div class="img">
          <img :src="caseListText" alt="" />
        </div>

        <div class="case-box">
          <list-card
            class="noPadding"
            :showDownName="false"
            :src="item.image"
            :routeQuery="item.routeQuery"
            v-for="(item, index) in relatedCaseList"
            :key="'box-3__item-1' + index"
            :keyIndex="index"
            :isPointer="false"
            :showFreeTrialTag="item.showFreeTrialTag"
            :backgroundColor="item.backgroundColor"
          ></list-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, getCurrentInstance } from "vue";
import * as templates from "../data.js";
import { useRouter } from "vue-router";

const props = defineProps({
  templateType: {
    type: String,
    default: "",
  },
});

const { proxy } = getCurrentInstance();
const { dataStore } = proxy.$usePiniaModule();

const router = useRouter();

const caseListText = require("@/assets/images/case-list-text.png");
const introduceText = require("@/assets/images/introduce-text.png");

// 当前模板参数
const currTemplateParam = computed(() => templates[props.templateType]);
//解构
const {
  bannerImage,
  projectIntroduction,
  detailContentImage,
  detailIntroduction,
  previewImage,
  freeTrialButtonConfig,
  relatedCaseList,
} = currTemplateParam.value;

// 试用按钮主题
const buttonTheme = computed(() => freeTrialButtonConfig.theme);
// 按钮定位样式
const btnPostionStyle = computed(() => freeTrialButtonConfig.style);

// 跳转返回
const jumpBack = () => {
  // 记录鼠标操作
  dataStore.setGlobalClickRecord(function () {
    document.getElementsByClassName("back-btn")[0].click();
  });

  goBack();
};
// 返回
const goBack = () => {
  router.go(-1);
};

// 点击试用按钮跳转页面
const jumpProject = () => {
  const { projectName, routeType } = freeTrialButtonConfig;
  if (routeType) {
    router.push({ path: "/webFramework", query: { projectName } });
  } else {
    router.push({ path: "/imitatepage", query: { projectName } });
  }
};
</script>

<style lang="scss" scoped>
.template-container {
  &.mobile-wrap {
    .is-wrapper-box {
    }

    .wrap-box {
      background-color: rgba(244, 248, 253, 1);
      .banner-box {
        position: relative;
        width: 100%;
        object-fit: cover;
        img {
          width: 100%;
        }

        .free-btn {
          position: absolute;
          box-sizing: border-box;
          .btn {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 75px;
            height: 25px;
            border-radius: 20px;
            cursor: pointer;
            box-shadow: 0px 10px 20px 1px rgba(0, 0, 0, 0.1);
            color: #fff;

            span {
              font-size: 8px;
            }
            // &.dark {
            //   background-color: #333333;
            //   color: white;
            // }
            // &.light {
            //   background-color: #fff;
            //   // color: #333333;
            // }
          }
        }
      }

      .introduce {
        text-align: center;
        padding: 20px;
        img {
          width: 100%;
        }
        .text {
          color: #333333;
          line-height: 18px;
          font-size: 12px;
          margin: 10px 0;
          text-align: center;
        }
      }

      .page-show {
        .page {
          border-radius: 6px;

          img {
            width: 100%;
          }
        }

        // 圆点
        // .round {
        //   display: flex;
        //   justify-content: center;

        //   > div {
        //     width: 20px;
        //     height: 20px;
        //     background-color: #b5cef2;
        //     box-shadow: 0px 2px 30px rgba(0, 108, 255, 0.2);
        //     border-radius: 50%;
        //     margin: 0 25px;
        //   }
        // }

        p {
          color: #333333;
          text-align: center;
          font-size: 12px;
          padding: 20px;
        }
      }

      .end-image {
        > img {
          width: 100%;
          object-fit: cover;
        }
        .back-btn {
          width: 75px;
          height: 32px;
          font-size: 14px;
          margin: 15px auto;
          margin-bottom: 0;
          background-color: #d9e9ff;
          color: rgba(0, 108, 255, 0.3803921569);
          text-align: right;
          line-height: 33px;
          border-radius: 50px;
          letter-spacing: 9px;
          box-sizing: border-box;
          padding: 0 10px;
        }
      }

      .case-list {
        margin: auto;
        padding: 20px;
        .img {
          text-align: center;
          width: 100%;
          img {
            width: 100%;
          }
        }

        .case-box {
          padding: 20px 0;
          padding-bottom: 0;

          .list-card-container {
            padding-bottom: 10px;
          }
        }
      }
    }
  }
}
</style>
