<template>
  <div class="template-container pc-wrap">
    <div class="wrap-box">
      <!-- banner -->
      <div class="banner-box" style="max-height: 5000px">
        <img :src="bannerImage" alt="" />

        <!-- 免费试用按钮 -->
        <div
          v-if="freeTrialButtonConfig"
          class="free-btn"
          :style="{
            left: btnPostionStyle.left,
            bottom: btnPostionStyle.bottom,
            color: btnPostionStyle.color,
          }"
          @click="jumpProject"
        >
          <div
            class="btn"
            :style="{
              backgroundColor: btnPostionStyle.backgroundColor,
              color: btnPostionStyle.color,
            }"
          >
            <span>免费试用按钮</span>
          </div>
        </div>
      </div>
      <!-- 项目介绍 -->
      <div class="introduce is-wrapper-box">
        <img :src="introduceText" alt="" />
        <p class="text fontRegular">
          {{ projectIntroduction }}
        </p>
      </div>

      <!-- 页面展示 -->
      <div class="page-show is-wrapper-box">
        <div class="page">
          <img :src="detailContentImage" alt="" />
        </div>

        <!-- 三个圆点 -->
        <!-- <div class="round" v-if="imgShow">
          <div v-for="item in 3"></div>
        </div> -->

        <p class="fontRegular">{{ detailIntroduction }}</p>
      </div>

      <!-- 预览图片 -->
      <div class="end-image is-wrapper-box">
        <img :src="previewImage" alt="" />
        <div class="back-btn fontRegular" @click="jumpBack">返回</div>
      </div>

      <!-- 相关案列 -->
      <div class="case-list is-wrapper-box">
        <div class="img">
          <img :src="caseListText" alt="" />
        </div>

        <div class="case-box">
          <list-card
            class="noPadding"
            :src="item.image"
            :routeQuery="item.routeQuery"
            v-for="(item, index) in relatedCaseList"
            :key="'box-3__item-1' + index"
            :keyIndex="index"
            :showFreeTrialTag="item.showFreeTrialTag"
            :backgroundColor="item.backgroundColor"
            :showDownName="false"
          ></list-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, toRefs, getCurrentInstance } from "vue";
import * as templates from "../data.js";
import { useRouter } from "vue-router";

const props = defineProps({
  templateType: {
    type: String,
    default: "",
  },
});

const { proxy } = getCurrentInstance();
const { dataStore } = proxy.$usePiniaModule();

const router = useRouter();

const caseListText = require("@/assets/images/case-list-text.png");
const introduceText = require("@/assets/images/introduce-text.png");

// 当前模板参数
const currTemplateParam = computed(() => templates[props.templateType]);
//解构
const {
  bannerImage,
  bannerHeight,
  projectIntroduction,
  detailContentImage,
  detailIntroduction,
  previewImage,
  relatedCaseList,
  freeTrialButtonConfig,
} = currTemplateParam.value;

// banner高度
// const heightValue = computed(() => {
//   const bannerValue = bannerHeight.value || "";
//   return bannerValue ? bannerValue : "750px";
// });

// 按钮定位样式
const btnPostionStyle = computed(() => freeTrialButtonConfig.style);

// 跳转返回
const jumpBack = () => {
  // 记录鼠标操作
  dataStore.setGlobalClickRecord(function () {
    document.getElementsByClassName("back-btn")[0].click();
  });

  goBack();
};
// 返回
const goBack = () => {
  router.go(-1);
};

// 点击试用按钮跳转页面
const jumpProject = () => {
  const { projectName, routeType } = freeTrialButtonConfig;
  if (routeType) {
    router.push({ path: "/webFramework", query: { projectName } });
  } else {
    router.push({ path: "/imitatepage", query: { projectName } });
  }
};
</script>

<style lang="scss" scoped>
.template-container {
  &.pc-wrap {
    .is-wrapper-box {
      // width: calc(100vw - 620px);
      width: 100%;
      max-width: 1320px;
      margin: auto;
    }

    .wrap-box {
      // background-color: rgba(244, 248, 253, 1);
      .banner-box {
        position: relative;
        // max-height: 750px;
        height: 100%;
        width: 100%;
        object-fit: cover;
        img {
          width: 100%;
          height: 100%;
        }

        // 免费试用按钮
        .free-btn {
          position: absolute;
          box-sizing: border-box;
          .btn {
            width: 223px;
            height: 68px;
            font-size: 22px;
            border-radius: 34px;
            line-height: 68px;
            text-align: center;
            cursor: pointer;
            box-shadow: 0px 10px 20px 1px rgba(0, 0, 0, 0.1);
            color: #fff;
            // &.dark {
            //   background-color: #333333;
            //   color: white;
            // }
            // &.light {
            //   background-color: #fff;
            //   // color: #333333;
            // }
          }
        }
      }

      .introduce {
        text-align: center;
        margin: auto;
        img {
          padding: 50px 0;
        }
        .text {
          font-size: 20px;
          color: #333333;
          line-height: 30px;
        }
      }

      .page-show {
        .page {
          margin: 60px auto 40px;
          // overflow: hidden;
          // height: 835px;
          border-radius: 6px;
          // overflow-y: auto;
          // scrollbar-width: none; /* firefox */
          // -ms-overflow-style: none; /* IE 10+ */

          // &::-webkit-scrollbar {
          //   width: 0;
          // }

          img {
            width: 100%;
          }
        }

        // 圆点
        // .round {
        //   display: flex;
        //   justify-content: center;

        //   > div {
        //     width: 20px;
        //     height: 20px;
        //     background-color: #b5cef2;
        //     box-shadow: 0px 2px 30px rgba(0, 108, 255, 0.2);
        //     border-radius: 50%;
        //     margin: 0 25px;
        //   }
        // }

        p {
          margin-top: 50px;
          font-size: 20px;
          color: #333333;
          line-height: 30px;
          text-align: center;
        }
      }

      .end-image {
        margin: 50px auto;
        > img {
          width: 100%;
          height: 425px;
          object-fit: cover;
        }
        .back-btn {
          width: 110px;
          height: 41px;
          background-color: #d9e9ff;
          color: #006cff61;
          text-align: right;
          line-height: 38px;
          border-radius: 50px;
          font-size: 24px;
          letter-spacing: 15px;
          box-sizing: border-box;
          padding: 0 10px;
          margin: 25px auto;
          cursor: pointer;
        }
      }

      .case-list {
        margin: auto;
        .img {
          text-align: center;
          width: 100%;
          padding-bottom: 100px;
        }

        .case-box {
          display: grid;
          grid-template-columns: repeat(3, 350px);
          grid-template-rows: 230px;
          justify-content: space-between;
          padding-bottom: 85px;
        }
      }
    }
  }
}
</style>
