<template>
  <div class="list-case-layout">
    <template-index :templateType="name"></template-index>
  </div>
</template>

<script setup>
import templateIndex from "./template/index.vue";

import { getCurrentInstance, toRefs } from "vue";

const { proxy } = getCurrentInstance();
const routeParams = proxy.$route.query;

const { name } = toRefs(routeParams);
</script>

<style lang="scss" scoped>
.list-case-layout {
  // display: grid;
  // grid-template-rows: auto 1fr auto;
  min-height: 100vh;
}
</style>
